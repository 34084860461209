import React, { useState, useEffect } from 'react';
import "../../styles/Home.css";
import "../../styles/Card.css";
import "../../styles/List.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

const ParamTeam = () => {
    const [groupedData, setGroupedData] = useState([]); // State to hold all teams' data
    const [availableManagers, setAvailableManagers] = useState([]); // State to hold all available managers
    const [availableRecruiters, setAvailableRecruiters] = useState([]); // State to hold all available recruiters
    const [selectedTeam, setSelectedTeam] = useState(null); // State to hold the currently selected team
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false); // State to indicate saving state
    const [showAddMainManager, setShowAddMainManager] = useState(false); // State to control visibility of Add Main Manager dropdown
    const [showAddManager, setShowAddManager] = useState(false); // State to control visibility of Add Manager dropdown
    const [showAddRecruiter, setShowAddRecruiter] = useState(false); // State to control visibility of Add Recruiter dropdown

    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');

    // Fetch all data (teams, managers, recruiters) from the backend
    const fetchGroupedData = async () => {
        const groupUrl = `${apiUrl}/api/managers_recruiters/`;

        try {
            const response = await fetch(groupUrl, {
                method: 'GET',
                headers: {
                    'Authorization': `Token ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();

            if (data && Array.isArray(data.teams) && Array.isArray(data.available_managers) && Array.isArray(data.available_recruiters)) {
                setGroupedData(data.teams);
                setAvailableManagers(data.available_managers);
                setAvailableRecruiters(data.available_recruiters);
            } else {
                throw new Error('Invalid data format');
            }

        } catch (error) {
            console.error('Error fetching grouped data:', error);
            setError('Failed to load data. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchGroupedData();  // Fetch data on component mount
    }, [apiUrl, token]);

    // Handler for selecting a team
    const handleTeamChange = (event) => {
        const teamId = event.target.value;
        const team = groupedData.find((team) => team.id === parseInt(teamId));
        setSelectedTeam({ 
            ...team, 
            main_manager: team.main_manager || null, 
            managers: team.managers || [], 
            recruiters: team.recruiters || [] 
        });  // Create a new object to avoid mutating state
    };

    // Handler for adding/removing main manager, manager, or recruiter
    const updateTeamMembers = async (newMainManager = null, newManagers = [], newRecruiters = []) => {
        if (!selectedTeam) return;

        setSaving(true);

        const updateUrl = `${apiUrl}/api/managers_recruiters/`;
        try {
            const response = await fetch(updateUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
                body: JSON.stringify({
                    team_id: selectedTeam.id,
                    main_manager: newMainManager ? newMainManager.id : null,
                    managers: Array.isArray(newManagers) ? newManagers.map(manager => manager.id) : [],
                    recruiters: Array.isArray(newRecruiters) ? newRecruiters.map(recruiter => recruiter.id) : [],
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to update team members');
            }

            const result = await response.json();

            if (result.success) {
                // Update the local state directly to show changes immediately
                setSelectedTeam(prevTeam => ({
                    ...prevTeam,
                    main_manager: newMainManager,
                    managers: newManagers,
                    recruiters: newRecruiters
                }));
            } else {
                console.error('Unexpected response format:', result);
                throw new Error('Unexpected response format');
            }

        } catch (error) {
            console.error('Error updating team members:', error);
            setError('Failed to update team members. Please try again later.');
        } finally {
            setSaving(false);
        }
    };

    // Handler for adding a main manager
    const handleAddMainManager = (manager) => {
        if (!selectedTeam) return; // Check if selectedTeam exists
        if (selectedTeam.main_manager && selectedTeam.main_manager.id === manager.id) return;  // Prevent duplicates
        setSelectedTeam(prevTeam => ({
            ...prevTeam,
            main_manager: manager
        }));
        updateTeamMembers(manager, selectedTeam.managers, selectedTeam.recruiters);  // Update backend
    };

    // Handler for removing a main manager
    const handleRemoveMainManager = () => {
        if (!selectedTeam) return;
        setSelectedTeam(prevTeam => ({
            ...prevTeam,
            main_manager: null
        }));
        updateTeamMembers(null, selectedTeam.managers, selectedTeam.recruiters);  // Update backend
    };

    // Handler for adding a manager
    const handleAddManager = (manager) => {
        if (!selectedTeam || selectedTeam.managers.some(m => m.id === manager.id)) return;  // Prevent duplicates
        const newManagers = [...selectedTeam.managers, manager];
        setSelectedTeam(prevTeam => ({
            ...prevTeam,
            managers: newManagers
        }));
        updateTeamMembers(selectedTeam.main_manager, newManagers, selectedTeam.recruiters);  // Update backend
    };

    // Handler for removing a manager
    const handleRemoveManager = (manager) => {
        if (!selectedTeam) return;
        const newManagers = selectedTeam.managers.filter(m => m.id !== manager.id);
        setSelectedTeam(prevTeam => ({
            ...prevTeam,
            managers: newManagers
        }));
        updateTeamMembers(selectedTeam.main_manager, newManagers, selectedTeam.recruiters);  // Update backend
    };

    // Handler for adding a recruiter
    const handleAddRecruiter = (recruiter) => {
        if (!selectedTeam || selectedTeam.recruiters.some(r => r.id === recruiter.id)) return;  // Prevent duplicates
        const newRecruiters = [...selectedTeam.recruiters, recruiter];
        setSelectedTeam(prevTeam => ({
            ...prevTeam,
            recruiters: newRecruiters
        }));
        updateTeamMembers(selectedTeam.main_manager, selectedTeam.managers, newRecruiters);  // Update backend
    };

    // Handler for removing a recruiter
    const handleRemoveRecruiter = (recruiter) => {
        if (!selectedTeam) return;
        const newRecruiters = selectedTeam.recruiters.filter(r => r.id !== recruiter.id);
        setSelectedTeam(prevTeam => ({
            ...prevTeam,
            recruiters: newRecruiters
        }));
        updateTeamMembers(selectedTeam.main_manager, selectedTeam.managers, newRecruiters);  // Update backend
    };

    // Toggle the visibility of Add Main Manager dropdown
    const toggleAddMainManager = () => {
        setShowAddMainManager(!showAddMainManager);
    };

    // Toggle the visibility of Add Manager dropdown
    const toggleAddManager = () => {
        setShowAddManager(!showAddManager);
    };

    // Toggle the visibility of Add Recruiter dropdown
    const toggleAddRecruiter = () => {
        setShowAddRecruiter(!showAddRecruiter);
    };

    return (
        <div className='card-full'>
            <div className='line-container-10'>
                <div className='display-line dropdown'>
                    <h5>Select the team</h5>

                    {loading && (
                        <div>
                            <FontAwesomeIcon icon={faSpinner} spin /> Loading...
                        </div>
                    )}

                    {/* Dropdown to select a team */}
                    <select className="dropdown" onChange={handleTeamChange} defaultValue="">
                        <option value="" disabled>Select a team</option>
                        {groupedData.map((team) => (
                            <option key={team.id} value={team.id}>
                                {team.name}
                            </option>
                        ))}
                    </select>
                </div>

                {!loading && !error && (
                    <>
                        {/* Display main manager, managers, and recruiters of the selected team */}
                        {selectedTeam && (
                            <div className='line-container-10'>
                                {/* Main Manager Section */}
                                <div className='display-line'>
                                    <FontAwesomeIcon className='user-info' icon={faPlusCircle} onClick={toggleAddMainManager} style={{ cursor: 'pointer', marginLeft: '8px' }} />
                                    Main manager of the team:
                                </div>

                                {selectedTeam.main_manager ? (
                                    <ul className='no-padding-list'>
                                        <li className='display-line'>
                                            {selectedTeam.main_manager.name.trim() ? selectedTeam.main_manager.name : 'Unnamed Main Manager'}
                                            <button className="btn-secondary" onClick={handleRemoveMainManager}>Remove</button>
                                        </li>
                                    </ul>
                                ) : (
                                    <p>No main manager available.</p>
                                )}

                                {/* Show Add Main Manager dropdown if state is true */}
                                {showAddMainManager && (
                                    <div className='dropdown'>
                                    <select onChange={(e) => handleAddMainManager(availableManagers.find(manager => manager.id === parseInt(e.target.value)))} defaultValue="">
                                        <option value="" disabled>Select a main manager to add</option>
                                        {availableManagers.map((manager) => (
                                            <option key={manager.id} value={manager.id}>
                                                {manager.name.trim() ? manager.name : 'Unnamed Main Manager'}
                                            </option>
                                        ))}
                                    </select>
                                    </div>
                                )}
                                <div className='perf-form-separator'></div>

                                {/* Managers Section */}
                                <div className='display-line'>
                                    <FontAwesomeIcon className='user-info' icon={faPlusCircle} onClick={toggleAddManager} style={{ cursor: 'pointer', marginLeft: '8px' }} />
                                    Recruiter managers:
                                </div>

                                {(selectedTeam.managers && selectedTeam.managers.length > 0) ? (
                                    <ul className='no-padding-list'>
                                        {selectedTeam.managers.map((manager) => (
                                            <li key={manager.id} className='display-line'>
                                                {manager.name.trim() ? manager.name : 'Unnamed Manager'}
                                                <button className="btn-secondary" onClick={() => handleRemoveManager(manager)}>Remove</button>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p>No managers available.</p>
                                )}

                                {/* Show Add Manager dropdown if state is true */}
                                {showAddManager && (
                                    <div className='dropdown'>
                                    <select onChange={(e) => handleAddManager(availableManagers.find(manager => manager.id === parseInt(e.target.value)))} defaultValue="">
                                        <option value="" disabled>Select a manager to add</option>
                                        {availableManagers.map((manager) => (
                                            <option key={manager.id} value={manager.id}>
                                                {manager.name.trim() ? manager.name : 'Unnamed Manager'}
                                            </option>
                                        ))}
                                    </select>
                                    </div>
                                )}
                                <div className='perf-form-separator'></div>

                                {/* Recruiters Section */}
                                <div className='display-line'>
                                    <FontAwesomeIcon className='user-info' icon={faPlusCircle} onClick={toggleAddRecruiter} style={{ cursor: 'pointer', marginLeft: '8px' }} />
                                    Recruiters:
                                </div>

                                {(selectedTeam.recruiters && selectedTeam.recruiters.length > 0) ? (
                                    <ul className='no-padding-list'>
                                        {selectedTeam.recruiters.map((recruiter) => (
                                            <li key={recruiter.id} className='display-line'>
                                                {recruiter.name.trim() ? recruiter.name : 'Unnamed Recruiter'}
                                                <button className="btn-secondary" onClick={() => handleRemoveRecruiter(recruiter)}>Remove</button>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p>No recruiters available.</p>
                                )}

                                {/* Show Add Recruiter dropdown if state is true */}
                                {showAddRecruiter && (
                                    <div className='dropdown'>
                                    <select onChange={(e) => handleAddRecruiter(availableRecruiters.find(recruiter => recruiter.id === parseInt(e.target.value)))} defaultValue="">
                                        <option value="" disabled>Select a recruiter to add</option>
                                        {availableRecruiters.map((recruiter) => (
                                            <option key={recruiter.id} value={recruiter.id}>
                                                {recruiter.name.trim() ? recruiter.name : 'Unnamed Recruiter'}
                                            </option>
                                        ))}
                                    </select>
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}

                {saving && (
                    <div>
                        <FontAwesomeIcon icon={faSpinner} spin /> Saving...
                    </div>
                )}
            </div>
        </div>
    );
};

export default ParamTeam;
