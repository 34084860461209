import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import '../styles/App.css';
import Home from './Home';
import Login from './Login';

import CreationEnvoi from './creation_compte/CreationEnvoi';
import CreateAccount from './creation_compte/CreateAccount';
import ResetPassword from './creation_compte/ResetPassword';

import RetentionPage from './main_pages/RetentionPage';
import RoutesUser from '../components/routes/RoutesUser';
import Lucca from './import/Lucca';
import amplitude from 'amplitude-js';
import RoutesCreateCSV from '../components/routes/RoutesCreateCSV';
import RoutesGraph from '../components/routes/RoutesGraph';
import RoutesInsight from '../components/routes/RoutesInsight';
import DiversityPage from './main_pages/DiversityPage';

import ReportsPage from './reports/ReportsPage';
import PerfPage from './main_pages/PerfPage';
import EffectifPage from './main_pages/EffectifPage';
import PerfGroupPage from './main_pages/PerfGroupPage';
import EmployeView from './main_pages/EmployeView';
import StaffingPlan from './main_pages/StaffingPlan';
import EquipeStaff from './recrutement/EquipeStaff';
import GraphStaffCompare from './graph_pages/staffingplan/GraphStaffCompare';
import GraphStaffEffectif from './graph_pages/staffingplan/GraphStaffEffectif';
import GraphStaffMasseSalariale from './graph_pages/staffingplan/GraphStaffMasseSalariale';

import InsightStaffLateTeam from './insights/recrutement/InsightStaffLateTeam';
import Unauthorized from './Unauthorized';
import InsightStaffLateLocation from './insights/recrutement/InsightStaffLateLocation';


const amplitudeInstance = amplitude.getInstance().init('71d93060b72e117d715ea203dc4e778c');

// Component to handle authentication
const RequireAuth = ({ children }) => {
  const token = localStorage.getItem('token');
  if (!token) {
    return <Navigate to="/login" />;
  }
  return children;
};

// Component to redirect if authenticated
const RedirectIfAuthenticated = ({ children }) => {
  const token = localStorage.getItem('token');
  if (token) {
    return <Navigate to="/" />;
  }
  return children;
};

// Main App component
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
          <RequireAuth>
            <Home />
          </RequireAuth>
        }/>
        <Route path="/login" element={
          <RedirectIfAuthenticated>
            <Login />
          </RedirectIfAuthenticated>
        } />

        <Route path="/user/*" element={
          <RequireAuth>
            <RoutesUser />
          </RequireAuth>
        } />
        <Route path="/create/*" element={
          <RequireAuth>
            <RoutesCreateCSV />
          </RequireAuth>
        } />
        <Route path="/graph/*" element={
          <RequireAuth>
            <RoutesGraph />
          </RequireAuth>
        } />
        <Route path="/insight/*" element={
          <RequireAuth>
            <RoutesInsight />
          </RequireAuth>
        } />

        <Route path="/diversite" element={
          <RequireAuth>
            <DiversityPage />
          </RequireAuth>
        } />
        <Route path="/effectif" element={
          <RequireAuth>
            <EffectifPage />
          </RequireAuth>
        } />

        <Route path="/performance/:reviewId?" element={
          <RequireAuth>
            <PerfPage />
          </RequireAuth>
        } />
        <Route path="/performance/groupe" element={
          <RequireAuth>
            <PerfGroupPage />
          </RequireAuth>
        } />
        <Route path="/employe/:employe_id?" element={
          <RequireAuth>
            <EmployeView />
          </RequireAuth>
        } />

        <Route path="/staffingplan" element={
          <RequireAuth>
            <StaffingPlan />
          </RequireAuth>
        } />
        <Route path="/staffingplan/team/:id" element={
          <RequireAuth>
            <EquipeStaff />
          </RequireAuth>
        } />
        <Route path="/staffingplan/insight/prevision" element={
          <RequireAuth>
            <GraphStaffCompare />
          </RequireAuth>
        } />
        <Route path="/staffingplan/graph/effectif" element={
          <RequireAuth>
            <GraphStaffEffectif />
          </RequireAuth>
        } />
        <Route path="/staffingplan/graph/massesalariale" element={
          <RequireAuth>
            <GraphStaffMasseSalariale />
          </RequireAuth>
        } />
        <Route path="/staffingplan/insight/lateteam" element={
          <RequireAuth>
            <InsightStaffLateTeam />
          </RequireAuth>
        } />
                <Route path="/staffingplan/insight/latelocation" element={
          <RequireAuth>

            <InsightStaffLateLocation/>
          </RequireAuth>
        } />

        <Route path="/creation/envoi" element={
          <RequireAuth>
            <CreationEnvoi />
          </RequireAuth>
        }/>
        <Route path="/create-account" element={
          <RequireAuth>
            <CreateAccount />
          </RequireAuth>
        }/>
        <Route path="/reset-password" element={<ResetPassword />}/>
        <Route path="/retention" element={
          <RequireAuth>
            <RetentionPage />
          </RequireAuth>
        }/>
        <Route path="/lucca" element={
          <RequireAuth>
            <Lucca />
          </RequireAuth>
        }/>


        <Route path="/reports" element={
          <RequireAuth>
            <ReportsPage />
          </RequireAuth>
        }/>

          <Route path="/unauthorized" element={
              <Unauthorized/>
        }/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
