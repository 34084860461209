import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import amplitude from 'amplitude-js';

const CreatePositionForm = ({
    newPositionInfo,
    handleCreateChange,
    handleCreateSubmit,
    handleBackClick
}) => {
    const { id } = useParams(); // Récupérer l'ID de l'équipe depuis l'URL
    const [expandedSections, setExpandedSections] = useState({
        general: true,
        salary: false,
        dates: false,
        additional: false,
    });

    const [managers, setManagers] = useState([]);
    const [recruiters, setRecruiters] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const storedUserData = localStorage.getItem('userData');
    const ampData = storedUserData ? JSON.parse(storedUserData) : {};

    useEffect(() => {
        const fetchManagersRecruiters = async () => {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`${apiUrl}/api/managers_recruiters/`, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    },
                });
                const data = await response.json();
                if (response.ok) {
                    console.log('Fetched data:', data); // Log fetched data

                    // Rechercher l'équipe avec l'ID correspondant
                    const team = data.teams.find(team => team.id === parseInt(id));
                    if (team) {
                        setManagers(team.managers);
                        setRecruiters(team.recruiters);
                    } else {
                        console.warn('No team found with the given ID:', id);
                    }
                } else {
                    console.error('Error fetching data:', data.error);
                }
            } catch (error) {
                console.error('Failed to fetch managers and recruiters:', error);
            }
        };

        if (id) {
            fetchManagersRecruiters();
        } else {
            console.warn('teamId is not defined'); // Log a warning if teamId is not defined
        }
    }, [id]);

    const toggleSection = (section) => {
        setExpandedSections({
            ...expandedSections,
            [section]: !expandedSections[section]
        });
    };

    const validateForm = () => {
        const requiredFields = ['job', 'arrival_month', 'job_opening_date'];
        const missingFields = requiredFields.filter(field => !newPositionInfo[field]);
        return missingFields;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const missingFields = validateForm();
        if (missingFields.length > 0) {
            setErrorMessage(`Champs manquants : ${missingFields.join(', ')}`);
            amplitude.getInstance().logEvent('OpenPositionCreated', {
                succeed: false,
                team: id || 'unknown',
                job: newPositionInfo.job,
                status: newPositionInfo.status,
                error: `Champs manquants : ${missingFields.join(', ')}`,
                username: ampData.username || 'unknown',
            });
        } else {
            setErrorMessage('');
            handleCreateSubmit(e);
            amplitude.getInstance().logEvent('OpenPositionCreated', {
                succeed: true,
                team: id || 'unknown',
                job: newPositionInfo.job,
                status: newPositionInfo.status,
                username: ampData.username || 'unknown',
            });
        }
    };

    return (
        <div className='card-half-full'>
            <h4>Create a new Open Position</h4>
            <form className="perf-form-body" onSubmit={handleSubmit}>
                <div className={expandedSections.general ? 'perf-form-section' : 'perf-form-section clickable'} onClick={!expandedSections.general ? () => toggleSection('general') : null}>
                    <h5 
                        className={expandedSections.general ? 'clickable' : ''} 
                        onClick={expandedSections.general ? () => toggleSection('general') : null}
                    >
                        General Information
                    </h5>
                    {expandedSections.general && (
                        <>
                            <div className='perf-form-element'>
                                <label className='perf-form-element-label'>Job Title</label>
                                <input
                                    className='perf-form-input'
                                    type="text"
                                    name="job"
                                    value={newPositionInfo.job}
                                    onChange={handleCreateChange}
                                    required
                                />
                            </div>
                            <div className='perf-form-element'>
                                <label className='perf-form-element-label'>Status</label>
                                <select
                                    className='perf-form-input'
                                    name="status"
                                    value={newPositionInfo.status } // Valeur par défaut
                                    onChange={handleCreateChange}
                                    required
                                    defaultValue={'0.Not Started'}
                                >
                                    <option value="0.Not Started">0.Not Started</option>
                                    <option value="1.Job Opened">1.Job Opened</option>

                                    <option value="6.Offer Accepted">6.Offer Accepted</option>
                                    <option value="7.Arrived">7.Arrived</option>
                                </select>
                            </div>
                            <div className='perf-form-element'>
                                <label className='perf-form-element-label'>Location</label>
                                <input
                                    className='perf-form-input'
                                    type="text"
                                    name="location"
                                    value={newPositionInfo.location}
                                    onChange={handleCreateChange}
                                />
                            </div>
                            <div className='perf-form-element'>
                                <label className='perf-form-element-label'>Hiring manager</label>
                                <select
                                    className='perf-form-input'
                                    name="contact_manager"
                                    value={newPositionInfo.contact_manager}
                                    onChange={handleCreateChange}
                                >
                                    <option value="">Select a hiring manager</option>
                                    {managers.map(manager => (
                                        <option key={manager.id} value={manager.id}>{manager.name || 'Unnamed Manager'}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='perf-form-element'>
                                <label className='perf-form-element-label'>Recruiter</label>
                                <select
                                    className='perf-form-input'
                                    name="recruiter"
                                    value={newPositionInfo.recruiter}
                                    onChange={handleCreateChange}
                                >
                                    <option value="">Select a recruiter</option>
                                    {recruiters.map(recruiter => (
                                        <option key={recruiter.id} value={recruiter.id}>{recruiter.name || 'Unnamed Recruiter'}</option>
                                    ))}
                                </select>
                            </div>
                        </>
                    )}
                </div>
                <div className='perf-form-separator'></div>
                <div className={expandedSections.salary ? 'perf-form-section' : 'perf-form-section clickable'} onClick={!expandedSections.salary ? () => toggleSection('salary') : null}>
                    <h5 
                        className={expandedSections.salary ? 'clickable' : ''} 
                        onClick={expandedSections.salary ? () => toggleSection('salary') : null}
                    >
                        Salary information
                    </h5>
                    {expandedSections.salary && (
                        <>
                            <div className='perf-form-element'>
                                <label className='perf-form-element-label'>Minimum salary</label>
                                <input
                                    className='perf-form-input'
                                    type="number"
                                    name="salary_min"
                                    value={newPositionInfo.salary_min}
                                    onChange={handleCreateChange}
                                />
                            </div>
                            <div className='perf-form-element'>
                                <label className='perf-form-element-label'>Maximum salary</label>
                                <input
                                    className='perf-form-input'
                                    type="number"
                                    name="salary_max"
                                    value={newPositionInfo.salary_max}
                                    onChange={handleCreateChange}
                                />
                            </div>
                        </>
                    )}
                </div>
                <div className='perf-form-separator'></div>
                <div className={expandedSections.dates ? 'perf-form-section' : 'perf-form-section clickable'} onClick={!expandedSections.dates ? () => toggleSection('dates') : null}>
                    <h5 
                        className={expandedSections.dates ? 'clickable' : ''} 
                        onClick={expandedSections.dates ? () => toggleSection('dates') : null}
                    >
                        Dates
                    </h5>
                    {expandedSections.dates && (
                        <>
                            <div className='perf-form-element'>
                                <label className='perf-form-element-label'>Expected Recruitment Date</label>
                                <input
                                    className='perf-form-input'
                                    type="date"
                                    name="arrival_month"
                                    value={newPositionInfo.arrival_month}
                                    onChange={handleCreateChange}
                                />
                            </div>

                            <div className='perf-form-element'>
                                <label className='perf-form-element-label'>Job Opening Date</label>
                                <input
                                    className='perf-form-input'
                                    type="date"
                                    name="job_opening_date"
                                    value={newPositionInfo.job_opening_date}
                                    onChange={handleCreateChange}
                                />
                            </div>
                        </>
                    )}
                </div>
                <div className='perf-form-separator'></div>
                <div className={expandedSections.additional ? 'perf-form-section' : 'perf-form-section clickable'} onClick={!expandedSections.additional ? () => toggleSection('additional') : null}>
                    <h5 
                        className={expandedSections.additional ? 'clickable' : ''} 
                        onClick={expandedSections.additional ? () => toggleSection('additional') : null}
                    >
                        Additional information
                    </h5>

                    {expandedSections.additional && (
                        <>
                            <div className='perf-form-element'>
                                <label className='perf-form-element-label'>Contract</label>
                                <input
                                    className='perf-form-input'
                                    type="text"
                                    name="contract"
                                    value={newPositionInfo.contract}
                                    onChange={handleCreateChange}
                                />
                            </div>
                            <div className='perf-form-element'>
                                <label className='perf-form-element-label'>Agency Involved</label>
                                <input
                                    className='perf-form-input'
                                    type="checkbox"
                                    name="agency"
                                    checked={newPositionInfo.agency}
                                    onChange={handleCreateChange}
                                />
                            </div>
                            <div className='perf-form-element'>
                                <label className='perf-form-element-label'>Priority</label>
                                <input
                                    className='perf-form-input'
                                    type="number"
                                    name="priority"
                                    value={newPositionInfo.priority}
                                    onChange={handleCreateChange}
                                />
                            </div>
                            <div className='perf-form-element'>
                                <label className='perf-form-element-label'>Comment</label>
                                <input
                                    className='perf-form-input'
                                    type="text"
                                    name="comment"
                                    value={newPositionInfo.comment}
                                    onChange={handleCreateChange}
                                />
                            </div>
                        </>
                    )}
                </div>
                <div className='perf-form-btn'>
                    <button type="submit">Create</button>
                    <button type="button" className='btn-secondary' onClick={handleBackClick}>Cancel</button>
                </div>
                {errorMessage && <div className="error-message">{errorMessage}</div>}
            </form>
        </div>
    );
};

export default CreatePositionForm;
