import React, { useState, useEffect, useCallback } from 'react';
import Sidebar from "../../components/Sidebar";
import colors from '../../assets/colors';
import { useNavigate } from 'react-router-dom';
import "../../styles/Home.css";
import "../../styles/Card.css";
import Tableau from "../../components/recrutement/Tableau";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faClipboardList, faBriefcase,  faUserCheck } from '@fortawesome/free-solid-svg-icons';
import OverviewRecrutement from '../recrutement/OverviewRecrutement';
import ImportStaff from '../recrutement/ImportStaff';
import Header from '../../components/Header';
import ParamTeam from '../recrutement/ParamTeam';

const StaffingPlan = () => {
    // Load the active tab from localStorage, or default to 'Overview'
    const loadActiveTab = () => {
        return localStorage.getItem('activeTabStaffingPlan') || 'Overview';
    };

    const [activeTab, setActiveTab] = useState(loadActiveTab());
    const [counts, setCounts] = useState({});
    const [groupedData, setGroupedData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const defaultVisibleColumns = ['team', 'contact_manager', 'num_open_positions', 'percentage_open_positions_completion'];

    // Load visible columns from localStorage or use default columns
    const loadVisibleColumns = () => {
        const storedColumns = localStorage.getItem('visibleColumnsStaffingPlan');
        if (storedColumns) {
            try {
                return JSON.parse(storedColumns);
            } catch (error) {
                console.error("Failed to parse visible columns from localStorage:", error);
                return defaultVisibleColumns;
            }
        }
        return defaultVisibleColumns;
    };

    // Save visible columns to localStorage
    const saveVisibleColumns = (columns) => {
        localStorage.setItem('visibleColumnsStaffingPlan', JSON.stringify(columns));
    };

    const [visibleColumns, setVisibleColumns] = useState(loadVisibleColumns());

    const columns = [
        { key: 'team', label: 'Team' },
        { key: 'contact_manager', label: 'Manager' }, // Utilisez contact_manager_name
        { key: 'num_open_positions', label: 'Open positions(2024)' },
        { key: 'percentage_open_positions_completion', label: 'Pourcentage completion(2024)' },
        { key: 'budget', label: 'Budget' },
    ];

    const handleRowClick = (row) => {
        navigate(`/staffingplan/team/${row.id}`);
    };

    const fetchGroupedData = useCallback(async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const groupUrl = `${apiUrl}/openposition/group_by_team/`;
        const token = localStorage.getItem('token');

        try {
            const response = await fetch(groupUrl, {
                method: 'GET',
                headers: {
                    'Authorization': `Token ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();

            // Formater les données pour avoir le nom du manager
            const formattedData = data.map(item => ({
                ...item,
            }));

            setGroupedData(formattedData);
            console.log("groupedData",formattedData);

        } catch (error) {
            console.error('Error fetching grouped data:', error);
            setError('Failed to load data. Please try again later.');
        }
    }, []);

    const fetchStatusCounts = useCallback(async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const statusUrl = `${apiUrl}/openposition/group_by_statut/`;
        const token = localStorage.getItem('token');

        try {
            const response = await fetch(statusUrl, {
                method: 'GET',
                headers: {
                    'Authorization': `Token ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            const counts = data.reduce((acc, item) => {
                acc[item.status] = item.number;
                return acc;
            }, {});
            setCounts(counts);
        } catch (error) {
            console.error('Error fetching status counts:', error);
            setError('Failed to load data. Please try again later.');
        }
    }, []);

    useEffect(() => {
        fetchGroupedData();
        fetchStatusCounts();
    }, [fetchGroupedData, fetchStatusCounts]);

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });

        const sorted = [...groupedData].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'asc' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'asc' ? 1 : -1;
            }
            return 0;
        });

        setGroupedData(sorted);
    };

    // Update visible columns and save to localStorage
    const handleVisibleColumnsChange = (columns) => {
        setVisibleColumns(columns);
        saveVisibleColumns(columns);
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        localStorage.setItem('activeTabStaffingPlan', tab); // Save the active tab to localStorage
    };

    const renderTabContent = () => {
        switch (activeTab) {
            case 'Plan':
                return <ImportStaff />;

            case 'Forecast':
                return (
                    <div className='line-container-10'>

                        <div className='cards-container'>
                            <div className="status-card">
                                <div className="status-left">
                                    <div className="status-icon status-not-started">
                                        <FontAwesomeIcon icon={faClipboardList} />
                                    </div>
                                    <div className="status-label">Not Started</div>
                                </div>
                                <div className="status-count">{counts['0.Not Started'] || 0}</div>
                            </div>

                            <div className="status-card">
                                <div className="status-left">
                                    <div className="status-icon status-job-opened">
                                        <FontAwesomeIcon icon={faBriefcase} />
                                    </div>
                                    <div className="status-label">Job Opened</div>
                                </div>
                                <div className="status-count">{counts['1.Job Opened'] || 0}</div>
                            </div>

                            <div className="status-card">
                                <div className="status-left">
                                    <div className="status-icon status-offer-accepted">
                                        <FontAwesomeIcon icon={faUserCheck} />
                                    </div>
                                    <div className="status-label">Offer Accepted</div>
                                </div>
                                <div className="status-count">{counts['6.Offer Accepted'] || 0}</div>
                            </div>

                            <div className="status-card">
                                <div className="status-left">
                                    <div className="status-icon status-arrived">
                                        <FontAwesomeIcon icon={faCheckCircle} />
                                    </div>
                                    <div className="status-label">Arrived</div>
                                </div>
                                <div className="status-count">{counts['7.Arrived'] || 0}</div>
                            </div>
                        </div>

                        <Tableau
                            data={groupedData}
                            columns={columns}
                            visibleColumns={visibleColumns}
                            setVisibleColumns={handleVisibleColumnsChange}
                            sortConfig={sortConfig}
                            onSort={handleSort}
                            onRowClick={handleRowClick}
                        />

                    </div>
                );

            case 'Overview':
                return <OverviewRecrutement />; // Render the Overview content
            case 'Param':
                    return <ParamTeam />; // Render the Overview content
            default:
                return null;
        }
    };

    return (
        <div style={{ backgroundColor: colors.background }} className='container-home'>
            <Sidebar />
            <div className="container-right">
                <Header/>
                <div className="tab-menu">
                <button
                        className={`tab-menu-btn ${activeTab === 'Plan' ? 'tab-menu-btn-active' : ''}`}
                        onClick={() => handleTabClick('Plan')}
                    >
                        Import/Export
                    </button>
                    <button
                        className={`tab-menu-btn ${activeTab === 'Overview' ? 'tab-menu-btn-active' : ''}`}
                        onClick={() => handleTabClick('Overview')}
                    >
                        Overview
                    </button>

                    <button
                        className={`tab-menu-btn ${activeTab === 'Forecast' ? 'tab-menu-btn-active' : ''}`}
                        onClick={() => handleTabClick('Forecast')}
                    >
                        Forecast
                    </button>
                    <button
                        className={`tab-menu-btn ${activeTab === 'Param' ? 'tab-menu-btn-active' : ''}`}
                        onClick={() => handleTabClick('Param')}
                    >
                        Settings
                    </button>
                </div>
                <div className="card-insight-separator"></div>
                <div className="tab-content">
                    {renderTabContent()}
                </div>
            </div>
        </div>
    );
};

export default StaffingPlan;
