import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import amplitude from 'amplitude-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import logo_txt from "../assets/logo_txt.png";
import "../styles/Login.css";
import "../styles/Sidebar.css";
import "../styles/Button.css";
import * as Sentry from '@sentry/react'; // Importer Sentry

const Login = () => {
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [showResetForm, setShowResetForm] = useState(false);
    const [resetSuccess, setResetSuccess] = useState(false);
    const [csrfToken, setCsrfToken] = useState('');

    const navigate = useNavigate();



    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setError('');
    };

    const fetchUserDataAndEntreprises = async () => {
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        if (!userData.username) {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`${apiUrl}/api/employe-log/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${token}`
                    }
                });

                if (!response.ok) {
                    Sentry.captureMessage('Failed to fetch user data - non-OK response', 'warning');
                    Sentry.setContext("API Error Details", {
                        url: `${apiUrl}/api/employe-log/`,
                        method: 'GET',
                        status: response.status,
                        responseText: await response.text(),
                    });
                    throw new Error('Erreur de réseau ou réponse non valide');
                }

                userData = await response.json();
                localStorage.setItem('userData', JSON.stringify(userData));
            } catch (error) {
                Sentry.captureException(error); // Capturer les exceptions avec Sentry
                console.error("Erreur lors de la récupération des données de l'utilisateur:", error);
                return;
            }
        }
    };

    const deleteCookie = (name) => {
        document.cookie = `${name}=; Max-Age=0; path=/; domain=${window.location.hostname}; secure; SameSite=Strict`;
    };

    const clearStorageAndCookies = () => {
        deleteCookie('sessionid');
        localStorage.removeItem('token');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        clearStorageAndCookies();

        const apiUrl = process.env.REACT_APP_API_URL;
        try {
            const response = await fetch(`${apiUrl}/api/login/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                if (response.status === 401) {
                    setError("Invalid Credentials. Please try again.");
                    amplitude.getInstance().logEvent('User LogIn', { statut: "failed", email: formData.email, reason: 'Invalid Credentials' });
                    Sentry.captureMessage('Invalid credentials provided', 'warning'); // Log avec Sentry pour les identifiants invalides
                } else {
                    Sentry.captureMessage(`HTTP Error during login: ${response.status}`, 'error');
                    Sentry.setContext("API Error Details", {
                        url: `${apiUrl}/api/login/`,
                        method: 'POST',
                        status: response.status,
                        responseText: await response.text(),
                    });
                    throw new Error(`HTTP Error: ${response.status}`);
                }
            }

            const json = await response.json();

            if (json.token) {
                localStorage.setItem('token', json.token);
                await fetchUserDataAndEntreprises();
                amplitude.getInstance().logEvent('User LogIn', { statut: "success", email: formData.email });
                navigate('/');
            } else {
                setError("Unable to log in with provided credentials.");
                amplitude.getInstance().logEvent('User LogIn', { statut: "failed", email: formData.email, reason: 'Unknown Error' });
                Sentry.captureMessage('Login failed due to unknown error', 'error');
                Sentry.setContext("API Error Details", {
                    url: `${apiUrl}/api/login/`,
                    method: 'POST',
                    responseText: JSON.stringify(json),
                });
            }
        } catch (error) {
            Sentry.captureException(error); // Capturer les exceptions avec Sentry
            console.error("Error sending data:", error);
            setError("Unable to log in with provided credentials.");
            amplitude.getInstance().logEvent('User LogIn', { statut: "failed", email: formData.email, reason: error.message });
        }
    };

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        try {
            const formData = new URLSearchParams();
            formData.append('user_email', email);

            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/api/send-reset-password/`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                credentials: 'include',
                body: formData
            });

            if (response.ok) {
                setResetSuccess(true);
                amplitude.getInstance().logEvent('User EmailResetPassword', { email, statut: 'success' });
            } else {
                amplitude.getInstance().logEvent('User EmailResetPassword', { email, statut: 'failed' });
                Sentry.captureMessage(`Password reset request failed: HTTP ${response.status}`, 'warning');
                Sentry.setContext("API Error Details", {
                    url: `${apiUrl}/api/send-reset-password/`,
                    method: 'POST',
                    status: response.status,
                    responseText: await response.text(),
                });
                throw new Error(`Erreur HTTP: ${response.status}`);
            }
        } catch (error) {
            Sentry.captureException(error); // Capturer les exceptions avec Sentry
            console.error("Erreur lors de la demande de réinitialisation:", error);
            alert("Impossible d'envoyer l'email de réinitialisation.");
            amplitude.getInstance().logEvent('User EmailResetPassword', { email, statut: 'failed', reason: error.message });
        }
    };

    const toggleResetForm = () => {
        setShowResetForm(!showResetForm);
        setResetSuccess(false);
        setError('');
    };

    return (
        <div className='padding-100'>
            <form onSubmit={showResetForm ? handlePasswordReset : handleSubmit} className="login-form">
                <div>
                    <img src={logo_txt} alt="Logo de l'entreprise" className='logo'></img>
                </div>
                {!showResetForm ? (
                    <>
                        <div className="form-column">
                            <label htmlFor="username">Email</label>
                            <input type="email" name="email" className="form-input" value={formData.email} onChange={handleChange} />
                        </div>
                        <div className="form-column">
                            <label htmlFor="password">Mot de passe</label>
                            <input type="password" name="password" className="form-input" value={formData.password} onChange={handleChange} />
                        </div>
                        <div className="line-margin-10">
                            <button type="submit" className="btn-primary">Se connecter</button>
                        </div>
                        <div className="line-margin-10">
                            <button type="button" onClick={toggleResetForm} className="btn-secondary">Mot de passe oublié ?</button>
                        </div>
                    </>
                ) : (
                    resetSuccess ? (
                        <div className="success-message">
                            <p>Vérifiez votre email pour obtenir les instructions sur la réinitialisation de votre mot de passe. N'oubliez pas de regarder dans votre dossier spam si vous ne trouvez pas l'email.</p>
                        </div>
                    ) : (
                        <>
                            <div className="form-column">
                                <input type="email" placeholder="Votre email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-input" />
                            </div>
                            <div className="line-margin-10">
                                <button type="submit" className="btn-primary">Envoyer email de réinitialisation</button>
                            </div>
                            <div className="line-margin-10">
                                <button onClick={toggleResetForm} className="btn-secondary">Retour à Connexion</button>
                            </div>
                        </>
                    )
                )}
                {error && (
                    <div className="error-message">
                        <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />
                        {error}
                    </div>
                )}
            </form>
        </div>
    );
};

export default Login;
