import React from 'react';
import { Bubble } from 'react-chartjs-2';
import 'chart.js/auto';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import amplitude from 'amplitude-js';

Chart.register(annotationPlugin);

const NineBoxMatrix = ({ chartData, onGroupClick }) => {
    // Define maximum bubble size after normalization
    const maxBubbleSize = 45;
    const storedUserData = localStorage.getItem('userData');
    const ampData = storedUserData ? JSON.parse(storedUserData) : {};
    // Find the maximum radius in the original data
    const maxR = Math.max(...chartData.datasets.flatMap(dataset => dataset.data.map(point => point.r)));

    // Normalize bubble sizes and store original radius
    const normalizedData = {
        ...chartData,
        datasets: chartData.datasets.map(dataset => ({
            ...dataset,
            data: dataset.data.map(point => ({
                ...point,
                originalR: point.r,  // Store the original radius
                r: (point.r * maxBubbleSize) / maxR  // Normalize the radius
            }))
        }))
    };

    // Handler for bubble click event
    const handleBubbleClick = (event, elements) => {
        if (!elements.length) return; // If no bubble was clicked, do nothing

        const element = elements[0]; // Get the first clicked element
        const datasetIndex = element.datasetIndex;
        const index = element.index;
        const clickedGroup = normalizedData.datasets[datasetIndex].data[index].group; // Assuming each data point has a 'group' field

        console.log('Clicked group:', clickedGroup); // Log the clicked group to the console

        if (onGroupClick) {
            onGroupClick(clickedGroup); // Invoke the onGroupClick callback with the selected group
            amplitude.getInstance().logEvent('NineBoxClicked', {
                username: ampData.username || 'unknown',
                group: clickedGroup,
            });
        }
    };

    return (
        <div className="card-full">
            <Bubble 
                data={normalizedData} 
                options={{
                    onClick: handleBubbleClick, // Attach the click handler
                    scales: {
                        x: {
                            type: 'linear',
                            position: 'bottom',
                            title: {
                                display: true,
                                text: 'Potentiel',
                                color: '#911',
                                font: {
                                    size: 16,
                                    weight: 'bold'
                                }
                            },
                            ticks: {
                                callback: function(value) {
                                    switch (value) {
                                        case 1: return 'Faible';
                                        case 2: return 'Moyenne';
                                        case 3: return 'Élevée';
                                        default: return '';
                                    }
                                }
                            }
                        },
                        y: {
                            type: 'linear',
                            position: 'left',
                            title: {
                                display: true,
                                text: 'Performance',
                                color: '#911',
                                font: {
                                    size: 16,
                                    weight: 'bold'
                                }
                            },
                            ticks: {
                                callback: function(value) {
                                    switch (value) {
                                        case 1: return 'Faible';
                                        case 2: return 'Moyenne';
                                        case 3: return 'Élevée';
                                        default: return '';
                                    }
                                }
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    const originalValue = context.raw.originalR;  // Access the original radius
                                    return `${context.dataset.label}: ${originalValue}`;
                                }
                            }
                        },
                        annotation: {
                            annotations: {
                                box1: {
                                    type: 'box',
                                    xMin: 0,
                                    xMax: 1,
                                    yMin: 0,
                                    yMax: 1,
                                    backgroundColor: '#FC123D50'
                                },
                                label1: {
                                    type: 'label',
                                    xValue: 0.5,
                                    yValue: 0.5,
                                    color: 'black',
                                    content: 'Sous performeur',
                                },
                                box2: {
                                    type: 'box',
                                    xMin: 1,
                                    xMax: 2,
                                    yMin: 0,
                                    yMax: 1,
                                    backgroundColor: '#F3916650'
                                },
                                label2: {
                                    type: 'label',
                                    xValue: 1.5,
                                    yValue: 0.5,
                                    color: 'black',
                                    content: 'Inconsistant',
                                },
                                box3: {
                                    type: 'box',
                                    xMin: 2,
                                    xMax: 3,
                                    yMin: 0,
                                    yMax: 1,
                                    backgroundColor: '#70CCCC50'
                                },
                                label3: {
                                    type: 'label',
                                    xValue: 2.5,
                                    yValue: 0.5,
                                    color: 'black',
                                    content: 'Diamant brut',
                                },
                                box4: {
                                    type: 'box',
                                    xMin: 0,
                                    xMax: 1,
                                    yMin: 1,
                                    yMax: 2,
                                    backgroundColor: '#F3916650'
                                },
                                label4: {
                                    type: 'label',
                                    xValue: 0.5,
                                    yValue: 1.5,
                                    color: 'black',
                                    content: 'Professionnel solide',
                                },
                                box5: {
                                    type: 'box',
                                    xMin: 0,
                                    xMax: 1,
                                    yMin: 2,
                                    yMax: 3,
                                    backgroundColor: '#70CCCC50'
                                },
                                label5: {
                                    type: 'label',
                                    xValue: 0.5,
                                    yValue: 2.5,
                                    color: 'black',
                                    content: 'Employé de confiance',
                                },
                                box6: {
                                    type: 'box',
                                    xMin: 1,
                                    xMax: 2,
                                    yMin: 1,
                                    yMax: 2,
                                    backgroundColor: '#70CCCC50'
                                },
                                label6: {
                                    type: 'label',
                                    xValue: 1.5,
                                    yValue: 1.5,
                                    color: 'black',
                                    content: 'Employé clé',
                                },
                                box7: {
                                    type: 'box',
                                    xMin: 1,
                                    xMax: 2,
                                    yMin: 2,
                                    yMax: 3,
                                    backgroundColor: '#7AE77550'
                                },
                                label7: {
                                    type: 'label',
                                    xValue: 1.5,
                                    yValue: 2.5,
                                    color: 'black',
                                    content: 'Star actuelle',
                                },
                                box8: {
                                    type: 'box',
                                    xMin: 2,
                                    xMax: 3,
                                    yMin: 1,
                                    yMax: 2,
                                    backgroundColor: '#7AE77550'
                                },
                                label8: {
                                    type: 'label',
                                    xValue: 2.5,
                                    yValue: 1.5,
                                    color: 'black',
                                    content: 'Future star',
                                },
                                box9: {
                                    type: 'box',
                                    xMin: 2,
                                    xMax: 3,
                                    yMin: 2,
                                    yMax: 3,
                                    backgroundColor: '#0F950850'
                                },
                                label9: {
                                    type: 'label',
                                    xValue: 2.5,
                                    yValue: 2.5,
                                    color: 'black',
                                    content: 'Futur Leader',
                                },
                            }
                        }
                    }
                }} 
            />
        </div>
    );
};

export default NineBoxMatrix;
