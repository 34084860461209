import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

const EditableMarkdown = ({ initialContent, contentModified }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [content, setContent] = useState(initialContent);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleChange = (e) => {
        const newContent = e.target.value;
        setContent(newContent);
        contentModified(newContent); // Notify the parent component of the content change
    };

    const handleBlur = () => {
        setIsEditing(false);
    };

    return (
        <div>
            {isEditing ? (
                <textarea
                    value={content}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                    className='text-area-open '
                />
            ) : (
                <div className='clickable' onClick={handleEditClick} style={{ position: 'relative' }}>
                    <ReactMarkdown>{content}</ReactMarkdown>
                    <FontAwesomeIcon icon={faPencilAlt} style={{ position: 'absolute', top: 5, right: 5 }} />
                </div>
            )}
        </div>
    );
};

export default EditableMarkdown;
