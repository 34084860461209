import React, { useState, useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import zxcvbn from 'zxcvbn'; // Bibliothèque pour évaluer la robustesse du mot de passe
import "../../styles/Login.css";
import logo_txt from "../../assets/logo_txt.png";

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [resetToken, setResetToken] = useState('');
    const [csrfToken, setCsrfToken] = useState('');
    const [isResetSuccessful, setIsResetSuccessful] = useState(false);
    const [error, setError] = useState('');
    const location = useLocation();
    const [passwordStrength, setPasswordStrength] = useState(null);

    useEffect(() => {
       
        const query = new URLSearchParams(location.search);
        const token = query.get('token');
        if (token) {
            try {
                jwtDecode(token);
                setResetToken(token);
            } catch (error) {
                console.error('Erreur de décodage du token:', error);
                alert('Le lien de réinitialisation est invalide ou a expiré.');
            }
        }
    }, [location]);




    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        setPasswordStrength(zxcvbn(newPassword).score);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setError('Les mots de passe ne correspondent pas.');
            return;
        }

        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/api/reset-password/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ password, token: resetToken })
            });

            if (response.ok) {
                alert('Mot de passe réinitialisé avec succès.');
                setIsResetSuccessful(true);
            } else {
                const errorData = await response.json();
                setError(`Erreur lors de la réinitialisation du mot de passe: ${errorData.error}`);
            }
        } catch (error) {
            console.error('Erreur lors de la réinitialisation du mot de passe:', error);
            setError('Une erreur est survenue lors de la réinitialisation du mot de passe.');
        }
    };

    const renderPasswordStrength = () => {
        if (passwordStrength === null) return null;

        const strengthLevels = ["Très faible", "Faible", "Moyen", "Fort", "Très fort"];
        const strengthColors = ["#e74c3c", "#e67e22", "#f1c40f", "#2ecc71", "#27ae60"];

        return (
            <div className="password-strength">
                <p>Robustesse du mot de passe: <span style={{ color: strengthColors[passwordStrength] }}>{strengthLevels[passwordStrength]}</span></p>
                <div className="strength-bar">
                    <div style={{ width: `${(passwordStrength + 1) * 20}%`, backgroundColor: strengthColors[passwordStrength] }} />
                </div>
            </div>
        );
    };

    if (isResetSuccessful) {
        return <Navigate to="/login" />;
    }

    return (
        <div className='container-center padding-100'>
           
            <form className="login-form" onSubmit={handleSubmit}>
            <div>
                    <img src={logo_txt} alt="Logo de l'entreprise" className='logo'></img>
                </div>

                <label className='label-column'>
                    Nouveau mot de passe
                    <input className='form-input' type="password" value={password} onChange={handlePasswordChange} required />
                </label>

                <label className='label-column'>
                    Confirmer le mot de passe
                    <input className='form-input' type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                </label>
                <button type="submit" className="btn-primary">Réinitialiser le Mot de Passe</button>
                {renderPasswordStrength()}
                {error && (
                    <div className="error-message shake">
                        <FontAwesomeIcon icon={faExclamationCircle} />
                        {error}
                    </div>
                )}
            </form>
        </div>
    );
};

export default ResetPassword;
