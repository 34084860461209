import React, { useState, useEffect, useCallback } from 'react';
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import IncreaseSelector from '../../components/IncreaseSelector';
import colors from '../../assets/colors';

import "../../styles/Home.css";
import "../../styles/Card.css";
import "../../styles/Table.css";
import KPICard from '../../components/cards/KPICard';

const PerfGroupPage = () => {
    const [data, setData] = useState({});
    const [increaseValues, setIncreaseValues] = useState([6, 4, 4, 2, 2, 2, 0, 0, 0]);
    const [projectedSalaries, setProjectedSalaries] = useState([]);
    const [salaryEvolution, setSalaryEvolution] = useState('');
    const [increaseCost, setIncreaseCost] = useState('');

    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');

    const fetchAnalysis = useCallback(async () => {
        const analysisUrl = `${apiUrl}/api/salaire/`;
        try {
            const response = await fetch(analysisUrl, {
                credentials: 'include',
                method: 'GET',
                headers: {
                    'Authorization': `Token ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const fetchedData = await response.json();
            setData(fetchedData);
            const newProjectedSalaries = calculateProjectedSalariesForNextMonths(fetchedData.salaire_mensuel);
            setProjectedSalaries(newProjectedSalaries);
            updateKPIs(newProjectedSalaries, fetchedData.salaire_mensuel);
        } catch (error) {
            console.error('Error fetching analysis:', error);
        }
    }, [apiUrl, token]);

    useEffect(() => {
        fetchAnalysis();
    }, [fetchAnalysis]);

    const handleValueChange = (index, newValue) => {
        const newValues = [...increaseValues];
        newValues[index] = newValue;
        setIncreaseValues(newValues);
        const newProjectedSalaries = calculateProjectedSalariesForNextMonths(data.salaire_mensuel);
        setProjectedSalaries(newProjectedSalaries);
        updateKPIs(newProjectedSalaries, data.salaire_mensuel);
    };

    const calculateProjectedSalariesForNextMonths = (historicalData) => {
        if (!historicalData || historicalData.length === 0) return [];
        
        const recentData = historicalData.slice(-12);
        const projectedSalaries = [1, 2, 3].map(month => {
            const date = new Date(new Date().setMonth(new Date().getMonth() + month)).toISOString().substring(0, 10);
            const totalProjectedSalary = rowTitles.reduce((total, title, index) => {
                const currentSalary = data.ninebox?.[title]?.total_salaire || 0;
                const projectedSalary = currentSalary * (1 + increaseValues[index] / 100);
                return total + projectedSalary;
            }, 0);
            return { date, salaire_total: totalProjectedSalary };
        });

        return [...recentData, ...projectedSalaries];
    };

    const updateKPIs = (projectedSalaries, historicalData) => {
        const lastHistoricalSalary = historicalData[historicalData.length - 1]?.salaire_total || 0;
        const lastProjectedSalary = projectedSalaries[projectedSalaries.length - 1].salaire_total;
        const budgetIncrease = calculateIncreaseBudget();
        const evolution = ((lastProjectedSalary - lastHistoricalSalary) / lastHistoricalSalary * 100).toFixed(1);
        
        setIncreaseCost(`${(budgetIncrease / 1000).toFixed(1)}k€`); // Displaying in thousands of euros
        setSalaryEvolution(`+${evolution}%`);
    };

    const calculateIncreaseBudget = () => {
        return rowTitles.reduce((total, title, index) => {
            const currentSalary = data.ninebox?.[title]?.total_salaire || 0;
            const increaseAmount = currentSalary * (increaseValues[index] / 100);
            return total + increaseAmount;
        }, 0);
    };

    const colNames = ["Nombre de salariés", "Augmentation prévue (en %)"];
    const rowTitles = [
        "Futur Leader",
        "Future star",
        "Star actuelle",
        "Diamant brut",
        "Employé clé",
        "Employé de confiance",
        "Inconsistant",
        "Professionnel solide",
        "Sous performeur"
    ];

    return (
        <div style={{ backgroundColor: colors.background }} className='container-home'>
            <Sidebar />
            <div className="container-right">
                <Header />
                <div className='cards-container'>
                    <KPICard kpi={salaryEvolution} text={"Evolution de la masse salariale"}/>
                    <KPICard kpi={increaseCost} text={"Coût des augmentations"}/>
                </div>
                <div className='card-full'>
                    <table className="table-custom">
                        <thead>
                            <tr>
                                <th>Groupe du 9box</th>
                                {colNames.map((colName, colIndex) => <th key={colIndex}>{colName}</th>)}
                                <th>Salaire Projeté</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rowTitles.map((title, rowIndex) => (
                                <tr key={rowIndex}>
                                    <td>{title}</td>
                                    <td>{data.ninebox?.[title]?.count || 'UNKNOWN'}</td>
                                    <td>
                                        <IncreaseSelector 
                                            initialValue={increaseValues[rowIndex]} 
                                            onValueChange={(newValue) => handleValueChange(rowIndex, newValue)} 
                                        />
                                    </td>
                                    <td>{(data.ninebox?.[title]?.total_salaire * (1 + increaseValues[rowIndex] / 100)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                   
                </div>
            </div>
        </div>
    );
};

export default PerfGroupPage;
